<div class="cards-list component" *ngIf="assetList?.length > 0">
    <div class="card shadow-box-dark card-list-item" *ngFor="let asset of assetList; index as i;">
        <div class="resale" *ngIf="asset?.resale?.onResale && isResellAllowed">
            <span>{{ 'myAssets.onResale' | translate }}</span>
        </div>

        <div class="auction-time inline-vertical-align" *ngIf="asset?.auction">
            <mat-icon class="primary" fontSet="material-icons-outlined">timer</mat-icon>
            <span>{{ asset?.auctionTimeExpiration }}</span>
        </div>

        <div class="auction" *ngIf="asset?.auction">
            <span>{{ 'myAssets.atAuction' | translate }}</span>
        </div>

        <div class="staked-container" *ngIf="isNFTStakingEnabled && asset?.on_staking && isAuthenticated"
            (mouseover)="openStakedModal(i)" (mouseout)="stakedContainerId = ''">
            <span>{{ 'myAssets.staked' | translate }}</span>
        </div>

        <mat-icon class="icon-position-fixed-right" *ngIf="asset?.reward_details && isNFTStakingEnabled && isAuthenticated"
            (mouseover)="openRewardModal(i)" (mouseout)="rewardIconId = ''">emoji_events</mat-icon>

        <div class="asset-image" [ngClass]="{'asset-image-asp' : displaySpecificInfoAsset(asset)}">
            <!-- Modal for Reward Details -->
            <app-nft-reward-modal [applicationId]="asset?.reward_details?.appl_id" *ngIf="asset?.reward_details && isNFTStakingEnabled && isAuthenticated"
                id="rewardModal" [show]="rewardIconId === ('reward-icon-' + i)"></app-nft-reward-modal>

            <!-- Modal for campaign detail -->
            <app-nft-staking-details-modal [show]="stakedContainerId === ('staked-container-' + i)"
                id="campaignModal" [NFTId]="asset?.nftId" *ngIf="isNFTStakingEnabled && asset?.on_staking && isAuthenticated"></app-nft-staking-details-modal>

            <img (load)="isImageLoaded = true" (click)="goToDetails(asset)" [src]="getMainPhoto(asset)" [alt]="asset?.description">
            <div class="container column" *ngIf="displaySpecificInfoAsset(asset) && isImageLoaded">
                <div class="specific-collection-info">
                    <div class="inline-vertical-align small-gap stamp-container">
                        <mat-icon class="big-icon" svgIcon="stamp-sos"></mat-icon>
                        <div class="text column">
                            <span class="light-text">{{ 'myAssets.NFT' | translate }}</span>
                            <strong class="base-yellow">{{ 'myAssets.certification' | translate }}</strong>
                            <h1 class="big-light-text">{{ asset?.name }}</h1>
                        </div>
                    </div>
                    <div class="content inline-vertical-align">
                        <div class="inline-vertical-align middle-gap">
                            <p class="column">
                                <span class="light-text">{{ 'myAssets.vol' | translate }}</span>
                                <span class="light-text light-bold">{{ 'myAssets.total' | translate }}</span>
                            </p>
                        </div>
                        <span class="big-light-text">{{ asset?.quantity || '-' }}</span>
                    </div>
                    <div class="content inline-vertical-align">
                        <div class="inline-vertical-align middle-gap">
                            <mat-icon class="light-icon" matSuffix>fingerprint</mat-icon>
                            <span class="light-text">{{ 'myAssets.company' | translate }}</span>
                        </div>
                        <span class="light-text light-bold end">{{ asset?.collection?.creator_institution || '-' }}</span>
                    </div>
                    <div class="content inline-vertical-align">
                        <div class="inline-vertical-align middle-gap">
                            <span class="material-icons-outlined light-icon">insert_drive_file</span>
                            <span class="light-text">{{ 'myAssets.collection' | translate }}</span>
                        </div>
                        <span class="light-text light-bold end">{{ (asset?.collection?.when | date: 'dd/MM/yyyy') || '-' }}</span>
                    </div>
                    <div class="content inline-vertical-align">
                        <div class="inline-vertical-align middle-gap">
                            <mat-icon class="blockchain-icon-light" svgIcon="blockchain"></mat-icon>
                            <span class="light-text">{{ 'myAssets.contractAddress' | translate }}</span>
                        </div>
                        <div class="inline-vertical-align small-gap">
                            <span class="light-text light-bold end">{{ getEllipsis(asset?.collection?.contract_address) || '-' }}</span>
                            <mat-icon class="material-icons-outlined light-icon" (click)="copyContractAddress(asset)"
                                [matTooltip]="'tooltip.copy' | translate" matTooltipClass="tooltip-primary">
                                content_copy
                            </mat-icon>
                        </div>
                    </div>
                    <div class="content inline-vertical-align">
                        <div class="inline-vertical-align middle-gap">
                            <mat-icon class="blockchain-icon-light" svgIcon="blockchain-logo"></mat-icon> 
                            <span class="light-text">{{ 'myAssets.blockchain' | translate }}</span>
                        </div>
                        <span class="light-text light-bold end">{{ asset?.collection?.network_id || '-' }}</span> 
                    </div>
                    <div class="content inline-vertical-align">
                        <div class="inline-vertical-align middle-gap">
                            <mat-icon class="blockchain-icon-light" svgIcon="blockchain-logo"></mat-icon>
                            <span class="light-text">{{ 'myAssets.type' | translate }}</span> 
                        </div>
                        <span class="light-text light-bold end">{{ asset?.nft_package_type || '-' }}</span> 
                    </div>
                </div>
                <div class="centralize column">
                    <span class="light-text light-bold small-size">{{ 'myAssets.photo' | translate }}: {{ 'Zoológico SP - Deni Willians' }}</span>
                    <div class="go-link" (click)="openExternalLink(asset)">
                        <div class="inline-vertical-align middle-gap">
                            <div class="column">
                                <span class="light-text light-bold small-size">{{ 'myAssets.discover' | translate }}</span>
                                <span class="light-text small-size">{{ asset?.collection?.name }}</span>
                            </div>
                            <span class="material-icons-outlined light-icon">open_in_new</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="info">
            <div class="info-content">
                <strong class="collection-name" (click)="goToCollectionProfile(asset?.collection_id)">
                    {{ asset?.collection?.name }}
                </strong>
                <span class="title-small" (click)="goToDetails(asset)"> {{ asset?.name }}</span>
                <span class="title-small-identity" *ngIf="asset?.blockchainId">{{ 'myAssets.id' | translate }} {{ asset?.blockchainId }}</span>
            </div>
            <div class="token-price-content" *ngIf="getAssetOriginalPrice(asset) && asset?.resale?.onResale">
                <span class="token-price original-value">{{ getSymbom(asset) }}</span>
                <span class="token-price original-value">{{ getAssetOriginalPrice(asset) | number:'1.2-2' }}</span>
            </div>
            <div class="token-price-content" *ngIf="getCorrectPrice(asset)">
                <span class="token-price">{{ getSymbom(asset) }}</span>
                <span class="token-price">{{ getCorrectPrice(asset) | number:'1.2-2' }}</span>
                <span class="token-small"  *ngIf="getAssetOriginalPrice(asset) && asset?.resale?.onResale">({{ getVariation(asset) }}%)</span>
            </div>
            <div class="token-price-content" *ngIf="!getCorrectPrice(asset)">
                <span class="token-price">{{ 'myAssets.buyIt' | translate  }}</span>
            </div>
            <div *ngIf="asset?.auction?.bids && currentPage === 'MY_ASSETS'">
                <span class="amountOfBids">{{ asset?.auction?.bids?.length }} {{ getAmountOfBidsText(asset?.auction?.bids?.length) }}</span>
            </div>
            <div class="bottom" *ngIf="currentPage !== 'MY_ASSETS'">
                <span *ngIf="asset?.availableTokens && !asset?.auction && !asset.collection.price">
                    {{ asset?.availableTokens | number }} 
                    {{ asset.availableTokens > 1 ? ('home.availableTokens' | translate) : ('home.availableTokensSingular' | translate) }}
                </span>

                <span *ngIf="asset?.availableTokens && !asset?.auction && asset.collection.price">
                    {{ asset?.availableTokens | number }} 
                    {{ asset.availableTokens > 1 ? ('productDetails.availableTokensInLot' | translate) : ('home.availableTokensSingular' | translate) }}
                </span>
                <span *ngIf="asset?.auction?.bids">
                    {{ asset?.auction?.bids?.length }} 
                    {{ getAmountOfBidsText(asset?.auction?.bids?.length) }}
                </span>
                <mat-icon class="primary" (click)="goToDetails(asset)">arrow_forward</mat-icon>
            </div>
            <div class="bottom" *ngIf="currentPage === 'MY_ASSETS'">
                <!-- View Link -->
                <mat-icon class="primary send-asset" [matTooltipClass]="'tooltip-primary'" [matTooltip]="'myAssets.goToLink' | translate" (click)="openExternalLink(asset)" *ngIf="asset?.external_link">trending_up</mat-icon>

                <!-- staking NFT -->
                <mat-icon class="primary send-asset" [matTooltipClass]="'tooltip-primary'" [matTooltip]="'myAssets.goToStaking' | translate" (click)="goToStakingNft()" *ngIf="isNFTStakingEnabled && asset?.on_staking">trending_up</mat-icon>
                
                <!--resale asset-->
                <mat-icon class="primary send-asset" [ngClass]="{'disabled': asset?.on_staking || asset?.auction}"[matTooltipClass]="'tooltip-primary'" [matTooltip]="setResaleTooltip(asset) | translate" *ngIf="isResellAllowed && !asset?.block_resell" (click)="resellAsset(asset, asset?.resale?.onResale)">shopping_cart</mat-icon>
                
                <!--auction asset-->
                <mat-icon class="primary send-asset" [ngClass]="{'disabled': asset?.on_staking || asset?.resale?.onResale || asset?.auction}" [matTooltipClass]="'tooltip-primary'" [matTooltip]="setAutionTooltip(asset) | translate" *ngIf="isResellAllowed && !asset?.block_resell" (click)="auctionAsset(asset, asset?.resale?.onResale)">monetization_on</mat-icon>
 
                <!--distribution asset-->
                <mat-icon class="primary send-asset" [ngClass]="{'disabled': asset?.on_staking || asset?.resale?.onResale || asset?.auction || asset?.block_liquidity_option}" [matTooltipClass]="'tooltip-primary'" *ngIf="distributeOnNFTs && asset?.is_distribuition_fiat"  [matTooltip]="'myAssets.receiveToken' | translate" (click)="changeDistributionType(asset, false)">money_off</mat-icon>
                <mat-icon class="primary send-asset" [ngClass]="{'disabled': asset?.on_staking || asset?.resale?.onResale || asset?.auction || asset?.block_liquidity_option}" [matTooltipClass]="'tooltip-primary'" *ngIf="distributeOnNFTs && !asset?.is_distribuition_fiat"  [matTooltip]="'myAssets.receiveFiat' | translate" (click)="changeDistributionType(asset, true)">attach_money</mat-icon>

                <!--split asset-->
                <mat-icon class="primary send-asset" [ngClass]="{'disabled': asset?.on_staking || asset?.resale?.onResale || asset?.auction}" [matTooltipClass]="'tooltip-primary'" [matTooltip]="setSplitTooltip(asset) | translate" *ngIf="isResellAllowed && !asset?.block_resell && asset.allow_split && asset?.price > 0" (click)="splitAsset(asset, asset?.resale?.onResale)">call_split</mat-icon>
                
                <!--send asset-->
                <mat-icon class="primary send-asset" [ngClass]="{'disabled': (asset?.resale?.onResale && isResellAllowed) || asset?.on_staking || asset?.auction}" [matTooltipClass]="'tooltip-primary'"
                [matTooltip]="setSendTooltip(asset) | translate"
                (click)="sendAsset(asset)" *ngIf="isSendNFTAllowed">send</mat-icon>
                
                <!--view asset-->
                <mat-icon class="primary send-asset" [matTooltipClass]="'tooltip-primary'" [matTooltip]="'myAssets.view' | translate" (click)="goToDetails(asset)">visibility</mat-icon>
            </div>
        </div>
    </div>
</div>
<mat-progress-spinner *ngIf="loading" class="spinner" [mode]="'indeterminate'"></mat-progress-spinner>
