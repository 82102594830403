import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthGuardService } from './guards/auth-guard.service';
import { FeatureGuardService } from './guards/feature-guard.service';
import { FeatureNames } from './shared/models/feature-names.enum';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: environment.mainRoute
    },
    {
        path: 'account',
        loadChildren: () =>
            import('./account/account.module').then((m) => m.AccountModule),
    },
    {
        path: 'terms',
        loadChildren: () =>
            import('./terms/terms.module').then((m) => m.TermsModule),
    },
    {
        path: 'privacy',
        loadChildren: () =>
            import('./privacy/privacy.module').then((m) => m.PrivacyModule),
    },
    {
        path: 'faq',
        loadChildren: () =>
            import('./faq/faq.module').then((m) => m.FaqModule),
    },
    {
        path: 'terms',
        loadChildren: () =>
            import('./terms/terms.module').then((m) => m.TermsModule),
    },
    {
        path: 'token-home',
        loadChildren: () =>
            import('./token-home/token-home.module').then((m) => m.HomeModule),
        canActivate: [AuthGuardService],
    },
    {
        path: 'navigation',
        loadChildren: () =>
            import('./internal-navigation/internal-navigation.module').then((m) => m.InternalNavigationModule),
        canActivate: [AuthGuardService],
    },
    {
        path: 'digital-banking',
        loadChildren: () =>
            import('./digital-banking/digital-banking.module').then((m) => m.DigitalBankingModule),
        canActivate: [FeatureGuardService],
        data: { featureRequired: FeatureNames.DIGITAL_BANKING }
    },
    {
        path: 'home',
        loadChildren: () =>
            import('./home/home.module').then((m) => m.HomeModule)
    },
    {
        path: '2fa',
        loadChildren: () =>
            import('./2fa/2fa.module').then((m) => m.MFAModule)
    },
    {
        path: 'exchange',
        loadChildren: () =>
            import('./exchange/exchange.module').then((m) => m.ExchangeModule)
    },
    {
        path: 'marketplace',
        loadChildren: () =>
            import('./marketplace/marketplace.module').then((m) => m.MarketplaceModule),
        canActivate: [AuthGuardService],
        data: { featureRequired: FeatureNames.MIDAS_MARKETPLACE }
    },
    {
        path: 'complete-your-registration',
        loadChildren: () =>
            import('./complete-your-registration/complete-your-registration.module').then((m) => m.CompleteYourRegistrationModule),
        canActivate: [AuthGuardService],
    },
    {
        path: 'tokenization',
        loadChildren: () =>
            import('./tokenization/tokenization.module').then((m) => m.TokenizationModule),
        data: { featureRequired: FeatureNames.MIDAS_MARKETPLACE }
    },
    {
        path: 'chat',
        loadChildren: () =>
            import('./chat/chat.module').then((m) => m.ChatModule),
        // canActivate: [AuthGuardService], // TODO
        // data: {featureRequired: FeatureNames.CHAT}
    },
    {
        path: 'profile',
        loadChildren: () =>
            import('./profile/profile.module').then((m) => m.ProfileModule),
        canActivate: [AuthGuardService],
    },
    {
        path: 'contracts',
        loadChildren: () =>
            import('./contracts/contracts.module').then((m) => m.ContractsModule),
        canActivate: [AuthGuardService],
    },
    {
        path: 'notifications',
        loadChildren: () =>
            import('./notifications/notifications.module').then((m) => m.NotificationsModule),
        canActivate: [AuthGuardService],
    },
    {
        path: 'limits',
        loadChildren: () =>
            import('./limits/limits.module').then((m) => m.LimitsModule),
        canActivate: [AuthGuardService],
    },
    {
        path: 'swap',
        loadChildren: () =>
            import('./swap/swap.module').then((m) => m.SwapModule),
        canActivate: [AuthGuardService],
        data: { featureRequired: FeatureNames.SWAP }
    },
    {
        path: 'external-deposit',
        loadChildren: () =>
            import('./external-deposit/external-deposit.module').then((m) => m.ExternalDepositModule)
    },
    {
        path: '**',
        redirectTo: 'home'
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }