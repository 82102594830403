import JSEncrypt from 'jsencrypt';
import { UserIdentifierEnum } from './user-identifier.enum'
import { AccountCreationRequest } from './bank.account.creation.model';

class EncryptedBase
{
    private static PRIVATE_KEY: string;

    constructor(rsaKey: string)
    {
        EncryptedBase.PRIVATE_KEY = rsaKey;
    }

    protected encrypt(msg: string): any
    {
        const encryptor = new JSEncrypt({});

        encryptor.setPublicKey(EncryptedBase.PRIVATE_KEY);

        return encryptor.encrypt(msg);
    }
}

export class SignupEncrypt extends EncryptedBase
{
    public email: string;
    public username: string;
    public firstName: string;
    public lastName: string;
    public passport: string;
    public password: string;
    public cpf: string;
    public cnpj: string;
    public indicationToken?: string;
    public documentToken?: string;
    public token_id?: string;
    public accountCreationRequest?: AccountCreationRequest;
    public account_type?: string;
    public nft_token?: string;
    public externalSourceIndication?: string;
    public phone?: string;
    public externalSourceId?: string;
    public isPhoneVerified?: boolean;

    constructor(
        email: string,
        password: string,
        username: string,
        firstName: string,
        lastName: string,
        documentId: string,
        indicationToken: string,
        rsaKey: string,
        documentType: UserIdentifierEnum | typeof UserIdentifierEnum,
        documentToken: string,
        token_id: string,
        accountCreationRequest: AccountCreationRequest,
        account_type: string,
        externalSourceIndication: string,
        nft_token: string,
        phone: string,
        externalSourceId: string,
        isPhoneVerified: boolean
        )
    {
        super(rsaKey);
        this.email = email;
        this.username = username;
        this.password = this.encrypt(password);
        this.firstName = firstName;
        this.lastName = lastName;
        this.cpf = '';
        this.cnpj = '';
        this.passport = '';
        this.indicationToken = indicationToken;
        this.documentToken = documentToken;
        this.nft_token = nft_token;
        this.account_type = account_type;
        this.token_id = token_id;
        this.accountCreationRequest = accountCreationRequest;
        this.externalSourceIndication = externalSourceIndication;
        this.phone = phone;
        this.externalSourceId = externalSourceId;
        this.isPhoneVerified = isPhoneVerified;

        if (documentType === UserIdentifierEnum.External) {
            this.passport = documentId;
        } else if (documentType === UserIdentifierEnum.CNPJ) {
            this.cnpj = documentId;
        } else if (documentType === UserIdentifierEnum.CPF) {
            this.cpf = documentId
        }

    }
}