import { Component, OnDestroy, OnInit, Output, Renderer2, RendererFactory2 } from '@angular/core';
import { Location } from '@angular/common';
import { Event, NavigationStart, Router } from '@angular/router';
import { EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin, Subject } from 'rxjs';
import { AccountService } from '../../services/account/account.services';
import { IExternalLinks } from '../../models/IExternalLinks';
import { ConfigReaderService } from '../../services/util/config.reader.service';
import { UtilService } from '../../services/util/util.service';
import { SidenavService } from '../../services/util/sidenav.service';
import { LanguagesEnum } from '../../models/languages.enum';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService, SessionStorageService } from 'angular-web-storage';
import { LocalStorageKeys } from '../../utils/local.storage.keys';
import { SidenavItem } from '../../models/sidenav-item.enum';
import { InternalPagesNames } from '../../models/internal-pages-name.enum';
import { FeaturesStatusService } from '../../services/util/features-status.service';
import { FeatureNames } from '../../models/feature-names.enum';
import { MultiplatFormEnum } from '../../models/multplatform.enum';
import { environment } from 'src/environments/environment';
import { TranslationConstants } from '../../services/util/translation.constants';
import { Clipboard } from '@angular/cdk/clipboard';
import { ReloadUserData } from '../../services/util/reload.user.data.service';
import { RedeemRewardModalComponent } from '../../redeem-reward-modal/redeem-reward-modal.component';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

    @Output() public returnLoggedUser: EventEmitter<any> = new EventEmitter();
    public externalLinks: IExternalLinks = undefined;
    public loading: boolean = false;
    public loggedUser: any = { firstName: '' };
    public onDestroy: any = new Subject<void>();
    public urls: Array<string> = [];
    public socialMedias: Array<string> = [];
    public hideSidebarIcon: boolean = true;
    public hideMediasIcon: boolean = false;
    public fiatDepositsEnabled: boolean = false;
    public selectedLanguage: LanguagesEnum;
    public cards: Array<SidenavItem> = [];
    public loadingLoggedUser: boolean = false;
    public isKYCEnabled: boolean = false;
    public isForeignUser: boolean = false;
    public digitalBankingEnabled: boolean = false;
    public exchangeEnabled: boolean = true;
    public paymentQueueEnabled: boolean = false;
    public showHeaderBuy: boolean = false;
    public marketplaceEnabled: boolean = true;
    public tokenizationEnabled: boolean = false;
    public isMultiplatform: boolean = false;
    public currentPlatform: MultiplatFormEnum = MultiplatFormEnum.WALLET;
    public platform: typeof MultiplatFormEnum = MultiplatFormEnum;
    public eventsEnabled: boolean = false;
    public walletEnabled: boolean = false;
    public in1888Enabled: boolean = false;
    public hasMfa: boolean = true;
    public crowdfundingEnabled: boolean = false;
    public chatEnabled: boolean = false;
    public copyValue: string;
    public userKey: string = '';
    public externalDeposit: boolean = false;
    private renderer: Renderer2;
    private isDarkMode = true;

    constructor(
        private readonly router: Router,
        private readonly accountService: AccountService,
        private readonly myConfigReaderService: ConfigReaderService,
        private readonly rendererFactory: RendererFactory2,
        public readonly localStorage: LocalStorageService,
        private readonly featureStatusService: FeaturesStatusService,
        private readonly location: Location,
        private readonly translationConstants: TranslationConstants,
        private readonly clipboard: Clipboard,
        public sidenav: SidenavService,
        private readonly reloadUserData: ReloadUserData,
        private readonly translate: TranslateService,
        private readonly sessionStorage: SessionStorageService,
        private readonly matDialog: MatDialog
    ) {
        this.renderer = rendererFactory.createRenderer(null, null);
        let theme = this.localStorage.get('theme');
        if(theme) {
            this.isDarkMode = theme === 'dark';
            const themeClass = this.isDarkMode ? 'dark-theme' : '';
            this.renderer.setAttribute(document.body, 'class', themeClass);
        } else {
            this.isDarkMode = environment.priority === 'dark';
            const themeClass = this.isDarkMode ? 'dark-theme' : '';
            this.renderer.setAttribute(document.body, 'class', themeClass);
        }
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
                this.urls.push(event.url);

                if (!this.loggedUser
                    && this.urls[this.urls.length - 2]
                    && this.urls[this.urls.length - 2].match('login')) {
                    this.userLogged();
                }
                this.getCurrentPlatform(event.url);
            }
        });

        this.featureStatusService.getFeatureStatus(FeatureNames.USER_KYC).subscribe(data => {
            if (data) {
                this.isKYCEnabled = data;
            }
        });
    }

    public ngOnInit(): void {
        this.loading = true;
        this.showHeaderBuy = environment.showHeaderBuy;
        this.checkTohideSidebarIcon();

        window.addEventListener('resize', () => {
            this.checkTohideSidebarIcon();
        });

        this.userLogged();
        this.loading = true;

        // To check if is necessary to update the user's detail info.
        setInterval(() => {
            this.reloadUserData.shouldreloadUserData$.subscribe(data => {
                if (data && !this.loadingLoggedUser) {
                    this.reloadUserData.reloadUserData.next(false);
                    this.userLogged();
                }
            })
        }, 1000);

        this.accountService.getLoggedUserDetails().subscribe(user => {
            this.userKey = user.id.substr(0, 8);
        }).add(() => {
            this.loading = false;
        });

        this.checkTohideSidebarIcon();

        window.addEventListener('resize', () => {
            this.checkTohideSidebarIcon();
        });

        this.myConfigReaderService.getAllExternalLinks().subscribe(contact => {
            this.copyValue = encodeURI(contact?.linkToMidas + this.userKey);
        }).add(() => {
        });


        this.featureStatusService.getFeatureStatus(FeatureNames.DIGITAL_BANKING, true).subscribe(status => {
            this.digitalBankingEnabled = status;
        });

        this.featureStatusService.getFeatureStatus(FeatureNames.FIAT_DEPOSITS).subscribe(status => {
            this.fiatDepositsEnabled = status;
        });

        this.featureStatusService.getFeatureStatus(FeatureNames.EXCHANGE).subscribe(status => {
            this.exchangeEnabled = status;
        });

        this.featureStatusService.getFeatureStatus(FeatureNames.IN1888_REPORT).subscribe(status => {
            this.in1888Enabled = status;
        });

        this.featureStatusService.getFeatureStatus(FeatureNames.MIDAS_MARKETPLACE).subscribe(status => {
            this.marketplaceEnabled = status;
        });

        this.featureStatusService.getFeatureStatus(FeatureNames.MIDAS_TOKENIZATION).subscribe(status => {
            this.tokenizationEnabled = status;
        });

        this.featureStatusService.getFeatureStatus(FeatureNames.CROWDFUNDING, true).subscribe(crowdfundingEnabled => {
            this.crowdfundingEnabled = crowdfundingEnabled;
        });

        this.featureStatusService.getFeatureStatus(FeatureNames.MIDAS_EVENTS).subscribe(status => {
            this.eventsEnabled = status;
        });

        this.featureStatusService.getFeatureStatus(FeatureNames.WALLET).subscribe(status => {
            this.walletEnabled = status;
        });

        this.featureStatusService.getFeatureStatus(FeatureNames.EXTERNAL_DEPOSIT).subscribe(status => {
            this.externalDeposit = status;
        });

        this.featureStatusService.getFeatureStatus(FeatureNames.CHAT).subscribe(status => {
            this.chatEnabled = status;
        });

        this.featureStatusService.getFeatureStatus(FeatureNames.PAYMENT_QUEUE).subscribe(status => {
            this.paymentQueueEnabled = status;
        });

        this.selectedLanguage = this.accountService.getUserLanguage();
        this.setLanguage();

        forkJoin([
            this.myConfigReaderService.getAllExternalLinks()
        ]).subscribe(([externalLinks]) => {
            this.externalLinks = externalLinks;
            this.socialMedias = Object.keys(this.externalLinks?.socialMidias) || [];
        }).add(() => {
            this.mountCard();
            this.isMultiplatform = this.digitalBankingEnabled || this.exchangeEnabled || this.tokenizationEnabled || this.marketplaceEnabled;
            this.loading = false;
        });
    }

    public userLogged(): void {
        this.accountService.isAuthenticated(true).subscribe(success => {
            if (success) {
                this.accountService.getLoggedUserDetails().subscribe((user: any) => {
                    this.isForeignUser = !user?.cpf && !user?.cnpj;
                    if (user?.firstName) {
                        this.loggedUser = user;
                        this.loggedUser.firstName = `${this.loggedUser.firstName.charAt(0).toUpperCase() + this.loggedUser.firstName.slice(1, this.loggedUser.firstName.length)}`;
                    }
                    this.returnLoggedUser.emit(this.loggedUser);
                });

                this.accountService.verifyMFAStatus().subscribe(mfa => {
                    this.hasMfa = mfa?.status;
                });
            }
        });
    }

    public async mountCard(): Promise<void> {
        let cards: Array<SidenavItem> = [];

        if (this.isKYCEnabled) {
            cards.push({ name: InternalPagesNames.PERSONAL_DOCUMENTS })
        }

        if (this.fiatDepositsEnabled) {
            cards.push({ name: InternalPagesNames.PAYMENTS })
        }

        this.cards = cards;
    }

    public goHome(): void {
        if (this.currentPlatform === MultiplatFormEnum.BANKING) {
            this.router.navigate(['/digital-banking']);
        } else if (this.currentPlatform === MultiplatFormEnum.CROWDFUNDING) {
            this.router.navigate(['/tokenization/crowdfunding']);
        } else if (this.currentPlatform === MultiplatFormEnum.MARKETPLACE) {
            this.router.navigate(['/marketplace/home']);
        } else if (this.currentPlatform === MultiplatFormEnum.TOKENIZATION) {
            this.router.navigate(['/tokenization/home']);
        } else {
            this.router.navigate(['/home']);
        }
    }

    public async logout(): Promise<void> {
        this.loading = true;
        this.accountService.destroySession().subscribe(() => {
            this.router.navigate(['/account/login']);
        }).add(() => {
            this.loggedUser = undefined;
            this.loading = false;
        });
    }

    public openPersonalDocumentsModal(): void {
        this.router.navigate(['/navigation/personal-documents'])
    }

    public open2fa(): void {
        this.router.navigate(['/2fa'])
    }

    public copyIndication(): void {
        this.clipboard.copy(this.copyValue);
    }

    public redeemReward(): void {
        const dialogRef = this.matDialog.open(RedeemRewardModalComponent, {});
    }

    public ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    public goToUrl(url: string): void {
        UtilService.goToUrl(url, this.externalLinks, this.router);
    }

    public goToDelete(): void {
        this.router.navigate(['/account/delete']);
    }

    /**
     * Identify the page and the screen width to display the
     * icon that will activate the Sidebar component.
     * The method is used when a new url is activated and when the screen is resized.
     */
    public checkTohideSidebarIcon(): void {
        const screenWidth: number = window.innerWidth || window.screen.width;
        const currentPath: string = this.location.path();

        if (screenWidth <= 768 || currentPath?.includes('external-deposit')) {
            this.hideMediasIcon = true;
        } else {
            this.hideMediasIcon = false;
        }

        if (!currentPath.includes('navigation')) {
            this.hideSidebarIcon = true;
        } else {
            if (screenWidth > 768) {
            } else {
                this.hideSidebarIcon = false;
            }
        }

        this.getCurrentPlatform(currentPath);
    }

    public toggleSidenav() {
        this.sidenav.toggle();
    }

    public setLanguage() {
        this.sessionStorage.set(LocalStorageKeys.LANGUAGE_LIST, this.selectedLanguage);
        this.translate.use(this.selectedLanguage);
    }

    public navigateToInternalPage(page: SidenavItem): void {
        this.router.navigate([`navigation/${page?.name}`]);
    }

    public navigateTo(route: string): void {
        this.router.navigate([route]);
    }

    public goToContact(): void {
        const contact = this.externalLinks?.contact;
        window.location.href = contact;
    }

    public getMainToken(): string {
        return environment.tokenExchange || '';
    }

    public getCurrentPlatform(url: string): void {
        if (url.includes('/digital-banking')) {
            this.currentPlatform = MultiplatFormEnum.BANKING;
        } else if (url.includes('crowdfunding')) {
            this.currentPlatform = MultiplatFormEnum.CROWDFUNDING;
        } else if (url.includes('tokenization')) {
            this.currentPlatform = MultiplatFormEnum.TOKENIZATION;
        } else if (url.includes('/exchange')) {
            this.currentPlatform = MultiplatFormEnum.EXCHANGE;
        } else if (url.includes('/marketplace')) {
            this.currentPlatform = MultiplatFormEnum.MARKETPLACE;
        } else if (url.includes('/chat')) {
            this.currentPlatform = MultiplatFormEnum.CHAT;
        } else {
            this.currentPlatform = MultiplatFormEnum.WALLET;
        }
    }

    toggleTheme(): void {
        this.isDarkMode = !this.isDarkMode;
        const themeClass = this.isDarkMode ? 'dark-theme' : '';
        if(this.isDarkMode) {
            this.localStorage.set('theme', 'dark');
        } else {
            this.localStorage.set('theme', 'light');
        }
        this.renderer.setAttribute(document.body, 'class', themeClass);
    }
}
